/*
Desktop Image: 1920x1080 pixels (or higher if your users are on large screens).
Tablet Image: 1280x800 pixels.
Mobile Image: 750x1334 pixels.
*/

/* General Styles */
body, html {
  background-image: url('/public/img/background-mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Trebuchet MS', Arial, sans-serif;
  color: #f5e6c4; 
  background-color: #2c2929;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Background images for header, content, and footer */
.header {
  /*
  background-image: url('/public/img/background-mobile-top.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  */
  padding: 10px 20px;
  height: 30px;
}

.cat {
  display:flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
}

/* Links */
a {
  color: #f5e6c4; /* Creamy color for links */
  text-decoration: none; /* Remove underline from links */
}

a:hover {
  text-decoration: none; /* Add underline on hover to indicate it's a link */
}

.content-wrap {
  flex: 1; /* Allow this element to grow and fill available space */
  padding: 20px;
  margin-top: 0;
  display: block; /* Use block display to ensure no flexbox centering occurs */
  width: 100%; /* Ensures the content wraps properly within the container */
  box-sizing: border-box; /* Ensures padding is included in width/height */
}

.footer {
  /*background-image: url('/public/img/background-mobile-bottom.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  */
  text-align: center;
  padding: 10px 20px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-top: auto;
}


.footer p {
  margin: 0;
}

.dreamcatcher-image {
  height: 47px;
  margin-bottom: 20px;
}

textarea {
  width: 100%;
  max-width: 500px;
  padding: 10px;
  background:transparent;
  background-color: #d3cec1;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: none;
}

button {
  padding: 8px 16px; /* Reduced padding */
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #2a3d66; /* Muted dark blue-gray color */
  color: #f5e6c4; /* Creamy color for text */
  border: none;
  border-radius: 8px; /* Increased border radius for a softer look */
  cursor: pointer;
  font-size: 14px; /* Slightly smaller font size */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
}

button:disabled {
  background-color: #2c2929;
}

button:hover {
  background-color: #364b7a; /* Slightly lighter color on hover */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow on hover */
}


.error {
  color: red;
  margin-top: 20px;
}

.response-container {
  background: rgba(26, 26, 46, 0.9); /* Slightly darken the background for readability */
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

/* navbar styles */
.navbar {
  display: flex;
  justify-content: space-between; /* Space between left and right content */
  align-items: center;
  padding: 5px 5px;
  background-color: transparent;
  
}

.navbar-left {
  display: flex;
  align-items: center; /* Align logo and text vertically */
}

.logo-image {
  height: 26;
  margin-right: 10px; /* Space between logo and text */
}

.navbar-right {
  text-align: right;
}

.navbar-brand {
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.navbar-login {
  text-decoration: none;
  font-size: 16px;
}

.navbar-user {
  color: #f5e6c4;
  text-decoration: none;
  font-size: 16px;
}


@media (max-width: 480px) {

  textarea {
    width: 100%;
  }

  button {
    width: 100%;
  }
}


.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: rgba(26, 26, 46, 0.1); /* Dark background with slight transparency */
  border-radius: 10px;
  max-width: 400px; /* Limit the width for a more compact design */
  margin: 0 auto; /* Center the container on the page */
}

.signup-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.signup-container input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #d3cec1;
  color: #333; /* Darker text color for readability */
}

.signup-container button {
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #2a3d66; /* Match the button color with the previous pages */
  color: #f5e6c4; 
  border: none;
  border-radius: 8px; /* Rounded corners for a softer look */
  cursor: pointer;
  font-size: 14px; /* Consistent font size */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  width: 100%; /* Make the button full width for better alignment */
}

.signup-container button:disabled {
  background-color: #2c2929;
}

.signup-container button:hover:not(:disabled) {
  background-color: #364b7a;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow on hover */
}

.signup-container .error {
  color: red;
  margin-top: 20px;
}

.signup-container h3 {
  font-size: 18px;
  margin: 20px 0;
}

.signup-container .social-login {
  width: 100%;
  margin-bottom: 10px;
}

.signup-container a {
  color: #f5e6c4; /* Match the link color */
  text-decoration: none;
}

.signup-container a:hover {
  text-decoration: underline;
}


.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: rgba(26, 26, 46, 0.1); /* Dark background with slight transparency */
  border-radius: 10px;
  max-width: 400px; /* Limit the width for a more compact design */
  margin: 0 auto; /* Center the container on the page */
}

.login-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.login-container input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #d3cec1;
  color: #333; /* Darker text color for readability */
}

.login-container button {
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #2a3d66; /* Match the button color with the previous pages */
  color: #f5e6c4; 
  border: none;
  border-radius: 8px; /* Rounded corners for a softer look */
  cursor: pointer;
  font-size: 14px; /* Consistent font size */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  width: 100%; /* Make the button full width for better alignment */
}

.login-container button:disabled {
  background-color: #2c2929;
}

.login-container button:hover:not(:disabled) {
  background-color: #364b7a;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow on hover */
}

.login-container .error {
  color: red;
  margin-top: 20px;
}

.login-container h3 {
  font-size: 18px;
  margin: 20px 0;
}

.login-container .social-login {
  width: 100%;
  margin-bottom: 10px;
}

.login-container a {
  color: #f5e6c4; /* Match the link color */
  text-decoration: none;
}

.login-container a:hover {
  text-decoration: underline;
}

/* QuestionComponent.css */
.question-container {
  text-align: center;
  padding: 40px;
  max-width: 600px;
  margin: 0 auto;
}

.question-text {
  font-size: 24px;
  margin-bottom: 30px;
}

.option-button {
  margin: 10px;
  padding: 15px 30px;
  font-size: 18px;
  cursor: pointer;
}

.slider-container {
  margin: 0 auto;
  width: 80%;
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 18px;
}

.next-button {
  margin-top: 30px;
  padding: 15px 30px;
  font-size: 18px;
  cursor: pointer;
}

.slider-input {
  width: 100%;
  height: 5px;
}